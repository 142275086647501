<!--销售毛利统计-->
<template>
  <div class="statement_box">
    <div 
      v-loading="dateLoading" 
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="statement_top_date">
      <div class="statement_date_query">
        <div class="statement_date_query_left">
            <!--年-->
          <el-select v-model="yearValue" placeholder="年" @change="handleDateChange('year')">
            <el-option
              v-for="item in yearOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--季度-->
          <el-select v-model="quarterValue" placeholder="季" @change="handleDateChange('quarter')">
            <el-option
              v-for="item in quarterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--月-->
          <el-select v-model="monthValue" placeholder="月" @change="handleDateChange('month')">
            <el-option
              v-for="item in monthOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--天-->
          <el-select v-model="dayValue" placeholder="日" @change="handleDateChange('day')">
            <el-option
              v-for="item in dayOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" :plain="isAll" @click="handleSearchBtn">全部</el-button>
          <el-date-picker
            class="date_range"
            v-model="pickerValue"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="onDatePickerChange">
          </el-date-picker>
          <el-radio v-model="staffValue" :label="0" @change="handleCheckBtn">按产品</el-radio>
          <el-radio v-model="staffValue" :label="1" @change="handleCheckBtn">按客户</el-radio>
        </div>
        <div class="statement_date_query_right">
          <div class="statement_explain">统计说明<i class="el-icon-question"></i></div>
          <el-button plain size="small" @click="exportStatement">导出</el-button>
        </div>
      </div>
      <div class="statement_date_content">
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">成交金额</div>
            <div class="statement_date_val">{{divisionMoney(collectData.billsDiscountsAmount)}}</div>
          </div>
        </div>
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">销售成本</div>
            <div class="statement_date_val">{{divisionMoney(collectData.costPriceAmount)}}</div>
          </div>
        </div>
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">生产成本</div>
            <div class="statement_date_val">{{divisionMoney(collectData.costProduct)}}</div>
          </div>
        </div>
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">毛利</div>
            <div class="statement_date_val">{{divisionMoney(collectData.billsGrossMargin)}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="statement_chart_box">
      <div class="statement_chart_query">
        <el-select v-model="chartValue" placeholder="请选择" @change="handleChartChange">
          <el-option
            v-for="item in chartOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div class="statement_chart_dec">
          <div class="statement_chart_sum">
            {{divisionMoney(collectData.beforeMoney)}}
          </div>
          <div class="statement_chart_text">
            <div>---</div>
            <div>相比上周期</div>
          </div>
        </div>
      </div>
      <div class="statement_chart_content" id="achievements_chart"></div>
    </div>
    <div v-show="staffValue === 0" class="statement_table_box">
      <div class="statement_table_title">按产品</div>
      <el-table
        :data="tableData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="productName"
          label="产品名称">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="productCode"
          label="产品编号">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billsProductCount"
          label="销售量">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billsAmount"
          label="订单金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billsDiscountsAmount"
          label="成交金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billsGrossMargin"
          label="毛利">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="costMaterial"
          label="原料成本">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="costProduct"
          label="生产成本">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="costOther"
          label="其它成本">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        :current-page="paginationOptions.page"
        :page-size="paginationOptions.size"
        :total="paginationOptions.total"
        @current-change="handleCurrentChange">
    </el-pagination>
    </div>
    <div v-show="staffValue === 1" class="statement_table_box">
      <div class="statement_table_title">按客户</div>
      <el-table
        :data="tableData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="customerName"
          label="客户名称">
          <template  slot-scope="scope">
            {{ scope.row.customerName || "暂无客户名"}}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="countTime"
          label="订单日期">
          <template slot-scope="scope">
            {{getSingleDate(scope.row.countTime)}}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billsAmount"
          label="订单金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billsDiscountsAmount"
          label="成交金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billsGrossMargin"
          label="毛利">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="costMaterial"
          label="原料成本">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="costProduct"
          label="生产成本">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="costOther"
          label="其它成本">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        :current-page="paginationOptions.page"
        :page-size="paginationOptions.size"
        :total="paginationOptions.total"
        @current-change="handleCurrentChange">
    </el-pagination>
    </div>
  </div>
</template>

<script>
  import grossMarginApi from '@/network/statement/market/grossMargin/index.js';
  import {parseTime} from '@/util/index.js';

  export default{
    data() {
      return{
        dateLoading: false,
        yearOptions: "",
        quarterOptions: "",
        monthOptions: "",
        dayOptions: "",
        yearValue: "",
        quarterValue: "",
        monthValue: "",
        dayValue: "",
        isAll: true,
        pickerValue: "",
        staffValue: 0,
        chartOptions: [{
          label: "销售数量",
          value: 1
        },{
          label: "成交金额",
          value: 2
        },{
          label: "毛利",
          value: 3
        },{
          label: "原料成本",
          value: 4
        }],
        chartValue: 1,
        collectData: "",
        chartData: [],
        tableData: [],
        paginationOptions: {
          page: 1,
          size: 10,
          total: 0
        }
      }
    },
    mounted() {
      this.getTimeist()
    },
    methods: {
      // 获取年季月
      getTimeist(){
        let t = new Date();
        let y = t.getFullYear()
        let m = t.getMonth()
        let q = parseInt(m/3) + 1
        // 获取本年本季本月
        this.yearValue = y
        this.quarterValue = q
        this.monthValue = m + 1
        // 设置下拉框展示的年
        let maxY = 10, yArr = []
        // 年
        for(let i = 0; i < maxY; i++){
          yArr.push({
            label: (y - i) + "年",
            value: y - i
          })
        }
        this.yearOptions = yArr
        // 季
        this.getQuarterList()
        // 月
        this.getMonthList(q)
        // 天
        this.getDayList(y, m)
        // 获取数据
        this.getList()
        this.getChartList()
        this.getCollectData()
      },
      // 获取季度
      getQuarterList(){
        // 季
        let qArr = []
        qArr.push({
          label: "全部",
          value: null
        })
        for(let i = 1; i <= 4; i++){
          qArr.push({
            label: "第" + i + "季度",
            value: i
          })
        }
        this.quarterOptions = qArr
      },
      // 根据季度获取月份
      getMonthList(q){
        let minM = q*3 - 2
        let maxM = q*3
        let mArr = []
        for(let i = minM; i <= maxM; i++){
          mArr.push({
            label: i + "月",
            value: i
          })
        }
        this.monthOptions = mArr
      },
      // 获取选中月份的天数
      getDayList(y,m){
        let maxD = new Date(y, m, 0).getDate()
        if(this.dayValue > maxD){
          this.dayValue = 1
        }
        let dArr = []
        for(let i = 1; i <= maxD; i++){
          dArr.push({
            label: i + "号",
            value: i
          })
        }
        this.dayOptions = dArr
      },
      // 监听下拉时间变动
      handleDateChange(type){
        this.isAll = true
        this.pickerValue = ""
        if(type === "year"){
          this.monthOptions = ""
          this.dayOptions = ""
          this.quarterValue = ""
          this.monthValue = ""
          this.dayValue = ""
          this.getQuarterList()
        }else if(type === "quarter"){
          this.dayOptions = ""
          this.monthValue = ""
          this.dayValue = ""
          this.getMonthList(this.quarterValue)
        }else if(type === "month"){
          this.dayValue = ""
          this.getDayList(this.yearValue, this.monthValue)
        }
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 查询全部
      handleSearchBtn(){
        this.pickerValue=""
        this.quarterOptions = ""
        this.monthOptions = ""
        this.dayOptions = ""
        this.yearValue = ""
        this.quarterValue = ""
        this.monthValue = ""
        this.dayValue = ""
        this.isAll = false
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 按字段时间范围查询
      onDatePickerChange(){
        this.quarterOptions = ""
        this.monthOptions = ""
        this.dayOptions = ""
        this.yearValue = ""
        this.quarterValue = ""
        this.monthValue = ""
        this.dayValue = ""
        this.isAll = this.pickerValue ? true : false
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 选中部门或者员工
      handleCheckBtn(){
        this.chartValue = 1
        if(this.staffValue === 0){
          this.chartOptions = [{
            label: "销售数量",
            value: 1
          },{
            label: "成交金额",
            value: 2
          },{
            label: "毛利",
            value: 3
          },{
            label: "原料成本",
            value: 4
          }]
        }else{
          this.chartOptions = [{
            label: "成交金额",
            value: 1
          },{
            label: "毛利",
            value: 2
          },{
            label: "原料成本",
            value: 3
          }]
        }
        this.getCollectData()
        this.getList()
        this.getChartList()
      },
      // 获取销售汇总信息
      getCollectData(){
        const [startTime, endTime] = this.pickerValue
        grossMarginApi.findSalesProfitReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          salesType: this.staffValue
        }).then((res)=>{
          if(res.code == 200){
            this.collectData = res.data || ""
          }
        })
      },
      // 获取销售数据
      getList(){
        const [startTime, endTime] = this.pickerValue
        grossMarginApi.findSalesProfitListReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          salesType: this.staffValue,
          pageIndex: this.paginationOptions.page,
          pageSize: this.paginationOptions.size,
        }).then((res)=>{
          if(res.code == 200){
            this.tableData = res.data.rows
            this.paginationOptions = {...this.paginationOptions,total:res.data.total}
          }
        })
      },
      // 获取图表数据
      getChartList(){
        const [startTime, endTime] = this.pickerValue
        grossMarginApi.findSalesProfitListReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          salesType: this.staffValue,
          pageIndex: 1,
          pageSize: 20,
        }).then((res)=>{
          if(res.code == 200){
            this.chartData = res.data.rows
            this.graphing()
          }
        })
      },
      // 表格数据
      _formatJson(filterVal, jsonData){
        let data = jsonData.map(row => filterVal.map(j => {
          let value = ''
          if(j === "countTime"){
            value = this.getSingleDate(row[j])
          }else{
            value = row[j]
          }
          return value
        }))
        return data
      },
      // 导出
      exportStatement(){
        const [startTime, endTime] = this.pickerValue
        grossMarginApi.findSalesProfitListReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          salesType: this.staffValue,
          pageIndex: 1,
          pageSize: 10000,
        }).then((res)=>{
          if(res.code == 200){
            import('@/util/Export2Excel').then(excel => {
              let excelObj = {
                productName: "产品名称",
                productCode: "产品编号",
                billsProductCount: "销售量",
                billsAmount: "订单金额",
                billsDiscountsAmount: "成交金额",
                billsGrossMargin: "毛利",
                costMaterial: "原料成本",
                costProduct: "生产成本",
                costOther: "其它成本",
              }
              if(this.staffValue === 1){
                excelObj = {
                  customerName: "客户名称",
                  countTime: "订单日期",
                  billsAmount: "订单金额",
                  billsDiscountsAmount: "成交金额",
                  billsGrossMargin: "毛利",
                  costMaterial: "原料成本",
                  costProduct: "生产成本",
                  costOther: "其它成本",
                }
              }
              const rows = res.data.rows
              const data = this._formatJson(Object.keys(excelObj), rows)
              excel.export_json_to_excel({
                header: Object.values(excelObj),
                data,
                filename: '销售毛利统计',
                autoWidth: true,
                bookType: 'xlsx',
              })
            })
          }
        })
      },
      // 金额展示添加逗号处理
      divisionMoney(val){
        if(val){
          let str = (val + '').split('.')
          let intSum = str[0].replace(/\B(?=(?:\d{3})+$)/g, ',')
          let dot = str[1] ? '.' + str[1] : ''
          let num = intSum + dot
          return num
        }else{
          return 0
        }
      },
      // 查询图标数据
      handleChartChange(){
        this.getChartList()
      },
      // 获取y轴名称
      getYName(){
        if(this.staffValue === 0){
          switch (this.chartValue) {
            case 1:
              return '销售数量'
            case 2:
              return '成交金额'
            case 3:
              return '毛利'
            default:
              return '原料成本'
          }
        }else{
          switch (this.chartValue) {
            case 1:
              return '成交金额'
            case 2:
              return '毛利'
            case 3:
              return '订单日期'
            default:
              return '原料成本'
          }
        }
      },
      // 获取x轴或者y轴数组
      getXName(x){
        let axisArr = []
        if(this.staffValue === 0){
          if(x){
            this.chartData.map(item=>{
              axisArr.push(item.productCode+'-'+item.productName)
            })
          }else{
            this.chartData.map(item=>{
              axisArr.push(this.chartValue === 1 ? item.billsProductCount : this.chartValue === 2 ? item.billsDiscountsAmount : this.chartValue === 3 ? item.billsGrossMargin : item.costMaterial)
            })
          }
        }else{
          if(x){
            this.chartData.map(item=>{
              axisArr.push(item.customerName || "暂无客户名")
            })
          }else{
            this.chartData.map(item=>{
              axisArr.push(this.chartValue === 1 ? item.billsDiscountsAmount : this.chartValue === 2 ? item.billsGrossMargin : item.costMaterial)
            })
          }
        }
        return axisArr
      }, 
      // 生成图表
      graphing(){
        let yName = this.getYName()
        let xAxisData = this.getXName(1)
        let yAxisData = this.getXName()
        let myChart = this.$echarts.init(document.getElementById('achievements_chart'))
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: 40,
            right: 10,
            bottom: 0,
            containLabel: true
          },
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                color: "#000000"
              },
              axisTick: {
                alignWithLabel: true,
                show: false
              },
              axisLine:{
                lineStyle: {
                  type: 'dashed',
                  color: '#cccccc',
                }
              },
              splitLine: {
                show: true,
                lineStyle:{
                  type: 'dashed'
                }
              }
            }
          ],
          xAxis: [
            {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                rotate: 70,
                color: "#000000"
              },
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              boundaryGap: false,
              axisLine:{
                lineStyle: {
                  type: 'dashed',
                  color: '#cccccc'
                }
              },
              splitLine: {
                lineStyle:{
                  type: 'dashed'
                }
              }
            }
          ],
          color: ['#0091ff'],
          series: [
            {
              name: yName,
              type: 'line',
              data: yAxisData,
              animation:true,
              areaStyle: {}
            }
          ]
        }
        myChart.setOption(option);
      },
      // 点击页数
      handleCurrentChange(e){
        this.paginationOptions = {...this.paginationOptions, page: e}
        this.getList()
      },
      //获取年月日
      getSingleDate(str) {
        return parseTime(str, '{y}-{m}-{d}')
      }
    }
  }
</script>

<style lang="less">
</style>
