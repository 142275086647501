
import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  //查询-条件查询销售毛利统计列表信息
  findSalesProfitListReport(data){
    return request({
      method:'POST',
      url:'/report/salesProfit/findSalesProfitListReport',
      data
    })
  },
  // 查询-条件查询销售毛利汇总信息
  findSalesProfitReport(data){
    return request({
      method:'POST',
      url:'/report/salesProfit/findSalesProfitReport',
      data
    })
  },
}